import React from "react";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import {Helmet} from "react-helmet";


class Rules extends React.Component {
    render() {
        return (
            <div className="h-100">
                <Helmet>
                    <title>Правила</title>
                </Helmet>
                <Header background="/images/about/top.jpg" content='Правила антикафе Зеленая дверь'/>
                <div className="container rules-container">
                    <div className="rules-well">
                        Чем же Вы можете заняться в нашем антикафе… Да чем пожелаете! А мы лишь предложим пару
                        вариантов:
                        <ul>
                            <li>поиграть в настольные игры</li>
                            <li>посидеть в интернете - WiFi у нас бесплатный!</li>
                            <li>познакомиться с новыми интересными людьми</li>
                            <li>почитать умные книжки</li>
                            <li>поиграть в приставку</li>
                            <li>поиграть в настольный теннис</li>
                            <li>обсудить выставку за чашечкой халявного чая:)</li>
                        </ul>
                        <p>Работаем круглосуточно</p>
                        <p>Оплата поминутная, по тарифу: первые два часа 3р/минуту, далее 2 ₽/минуту</p>
                        <p>Стопчек: за всё время с 9.00 до 9.00 вы заплатите не более 690 ₽.</p>
                        <p>
                            Для студентов стопчек - 490 ₽.
                            Обратите внимание, что этот тариф будет действителен только при предъявлении
                            студенческого билета.
                        </p>
                    </div>
                    <h2>Подробно:</h2>
                    <ol>
                        <li>
                            Нахождение в заведении несовершеннолетних (до 18 лет) после 22.00 не допускается.
                            При входе мы можем отказать в посещении при отсутствии документов, подтверждающих возраст.
                        </li>
                        <li>Администрация может отказать в посещении без объяснения причин.</li>
                        <li>
                            Мы будем вынуждены отказать Вам в посещении, если:
                            <ul>
                                <li>
                                    Вы находитесь в состоянии алкогольного опьянения
                                    (От Вас пахнет алкоголем, Вас пошатывает, Вы неадекватны. И да, даже если чуть-чуть)
                                </li>
                                <li>Вы ведете себя недружелюбно/неадекватно или вели в прошлые посещения.
                                    (На усмотрение старшего администратора).
                                </li>
                                <li>Вы нарушаете общественный порядок (На усмотрение старшего администратора)</li>
                                <li>Вы совершаете противоправные действия (!)</li>
                            </ul>
                        </li>
                        <li>
                            В Зеленой Двери запрещается:
                            <ul>
                                <li>Заниматься несанкционированной торговлей (даже если чуть-чуть).</li>
                                <li>Приносить и распивать алкоголь (В том числе приносить в себе).</li>
                                <li>Нарушать УК и АК РФ.</li>
                                <li>
                                    Играть в азартные игры, играть в игры на деньги.
                                    Покер на территории РФ является азартной игрой.
                                    Об этом можно много спорить, но мы спорить не будем.
                                </li>
                                <li>
                                    Нарушать общественный порядок
                                    (Орать громко, бить сильно, крушить все на своем пути, приставать к людям не
                                    рекомендуется)
                                </li>
                                <li>Курить в непредназначенных для этого местах. (В помещениях, снаружи вблизи входов и
                                    окон)
                                </li>
                                <li>Включать свою музыку через колонки (Только в случае предварительного согласования со
                                    старшим
                                    администратором)
                                </li>
                                <li>
                                    Ругаться матом, говорить матом и петь матом тоже не стоит
                                    (во всяком случае так, чтобы слышали другие)
                                </li>
                                <li>
                                    Посещение уборных двумя или более людьми
                                    (Ваше посещение уборной группой от двух более человек будет расценено как митинг и
                                    будет разогнано)
                                </li>
                                <li>Оспаривать решения администратора (даже если он бука!)</li>
                                <li>
                                    Посещать наше заведение в спортивной одежде и находиться в головных уборах внутри
                                    помещения.
                                    (Это невежливо!)
                                </li>
                                <li>Находиться в местах, предназначенных только для персонала. (Стойка администратора,
                                    например)
                                </li>
                                <li>Нельзя отвлекать администратора за стойкой.</li>
                                <li>
                                    Проводить политические, религиозные, ЗОЖ и т.д. агитации,
                                    которые могут задеть чувства старшего администратора.
                                </li>
                            </ul>
                        </li>
                        <li>
                            Штрафные санкции Зеленой Двери:
                            <ul>
                                <li>
                                    Потеря и/или поломка Вашей пластиковой карты. Штраф - 200 рублей.
                                    (Задний карман джинс они не любят!)
                                </li>
                                <li>
                                    Находиться на территории АнтиКафе более 10 минут не записанным.
                                    Штраф от 200 до 1000 рублей. (Двор -тоже часть нашего заведения)
                                </li>
                            </ul>
                        </li>
                        <li>
                            По вопросам бронирования Вы можете обратиться к администратору за стойкой, либо к Старшему
                            Администратору.
                            Также позвонить по телефону: <a href="tel:+79684637491">+7 968 463 74-91</a><br/>
                            Бронирование столов, залов осуществляется только по предоплате. (Подробности можно уточнить
                            у
                            администратора)
                        </li>
                        <li>Перестановка мебели возможна только при согласовании с администратором. (Нам тоже
                            интересно!)
                        </li>
                        <li>
                            Если Вы хотите:
                            <ul>
                                <li>Провести мероприятие (лекция, мастер-класс, семинар, не день рождения)</li>
                                <li>Оставить отзыв или пожелание</li>
                                <li>В случае спорной ситуации</li>
                                <li>
                                    Вы можете написать на нашу почту <a
                                    href="mailto:event@zdver.com">event@zdver.com</a>.
                                    Вам ответят!
                                </li>
                            </ul>
                        </li>
                    </ol>
                </div>
                <Footer/>
            </div>
        );
    }
}

export default Rules;
